import React from "react";
import PropTypes from "prop-types";
import BaseSelect from "react-select";

const noop = () => {
  // no operation (do nothing real quick)
};

export class Select extends React.Component {
  selectRef = null;
  setSelectRef = (ref) => {
    this.selectRef = ref;
  };

  onChange = (value, actionMeta) => {
    this.props.onChange(value, actionMeta);
    this.setState({
      value,
    });
  };

  render() {
    const { required, ...props } = this.props;
    const { isDisabled } = this.props;
    const enableRequired = !isDisabled;

    return (
      <div>
        <BaseSelect
          {...props}
          ref={this.setSelectRef}
          onChange={this.onChange}
        />
        {enableRequired && (
          <input
            tabIndex={-1}
            autoComplete="off"
            style={{
              opacity: 0,
              width: "100%",
              height: 0,
              position: "absolute",
            }}
            value={this.props.value || ""}
            onChange={noop}
            onFocus={() => this.selectRef.focus()}
            required={required}
          />
        )}
      </div>
    );
  }
}

Select.defaultProps = {
  onChange: noop,
};

// Select.protoTypes = {
//   onChange: PropTypes.func,
//   required: PropTypes.bool,
// };

// export default Select;

Select.propTypes = {
  onChange: PropTypes.func,
  required: PropTypes.bool,
  isDisabled: PropTypes.bool,
  value: PropTypes.string,
};
