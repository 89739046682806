/**
 * Entry application component used to compose providers and render Routes.
 */
/* global process */
import React, {useEffect} from "react";
import {BrowserRouter} from "react-router-dom";
import {SnackbarProvider as NotificationProvider, useSnackbar,} from "notistack";
import {
  AuthProvider,
  ConfirmationProvider,
  GraphQLProvider,
  I18nProvider,
  LocaleProvider,
  MaterialThemeProvider,
  UserProvider,
} from "./providers";
import {ErrorBoundary} from "./components";
// import {LayoutSplashScreen,
//   MetronicLayoutProvider,
//   MetronicSplashScreenProvider,
// MetronicSubheaderProvider} from "_metronic/layout";
import AppRoutes from "./Routes";
import Hotjar from "@hotjar/browser";
import CssBaseline from "@mui/material/CssBaseline";
import VersionControl from "./components/VersionControl";
import PropTypes from "prop-types";
import {IconButton} from "@mui/material";
import {IoMdClose} from "react-icons/io";

const { PUBLIC_URL, REACT_APP_NODE_ENV } = process.env;
let siteId = 3692176;
const hotjarVersion = 6;

switch (REACT_APP_NODE_ENV) {
  case "production":
    siteId = 3692176;
    break;
  case "staging":
    siteId = 3692279;
    break;
  default:
    siteId = 3692282;
    break;
}

/**
 *
 */
export default function App() {
  useEffect(() => {
    if (
      REACT_APP_NODE_ENV === "production" ||
      REACT_APP_NODE_ENV === "staging"
    ) {
      Hotjar.init(siteId, hotjarVersion);
    }
  }, []);

  const notificationPosition = {
    vertical: "bottom",
    horizontal: "left",
  };
  return (
    <React.StrictMode>
      <CssBaseline />
      <BrowserRouter basename={PUBLIC_URL}>
        <NotificationProvider
          anchorOrigin={notificationPosition}
          autoHideDuration={6000}
          preventDuplicate={true}
          action={(key) => <CloseButton id={key} />}
        >
          <AuthProvider>
            <UserProvider>
              <LocaleProvider>
                <GraphQLProvider>
                  <ErrorBoundary>
                    {/* <MetronicLayoutProvider> */}
                    {/*   <MetronicSubheaderProvider> */}
                    {/*     <MetronicSplashScreenProvider> */}
                    {/* <SplashScreenProvider> */}
                    {/* <React.Suspense fallback={<LayoutSplashScreen />}> */}
                    {/* <React.Suspense fallback={<SplashScreen />}> */}
                    <VersionControl>
                      {/* <CustomerPermissionProvider> */}
                      <MaterialThemeProvider>
                        <I18nProvider>
                          <ConfirmationProvider>
                            {/* <SplashScreen /> */}
                            <AppRoutes />
                          </ConfirmationProvider>
                        </I18nProvider>
                      </MaterialThemeProvider>
                      {/* </CustomerPermissionProvider> */}
                    </VersionControl>
                    {/* </React.Suspense> */}
                    {/* </SplashScreenProvider> */}
                    {/* </MetronicSplashScreenProvider> */}
                    {/* </MetronicSubheaderProvider> */}
                    {/* </MetronicLayoutProvider> */}
                  </ErrorBoundary>
                </GraphQLProvider>
              </LocaleProvider>
            </UserProvider>
          </AuthProvider>
        </NotificationProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}

const CloseButton = ({ id }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton size="small" onClick={() => closeSnackbar(id)} color="inherit">
      <IoMdClose />
    </IconButton>
  );
};

CloseButton.propTypes = {
  id: PropTypes.node,
};
